import React from 'react';
import PropTypes from 'prop-types';

const MailingList = ({ filteredData }) => {
  const handleUserClick = (user) => {
    // Handle the user click event
    console.log('User clicked:', user);
      console.log(filteredData, 'filteredData')
  };

  return (
    <table className='table user-table-container__table-block'>
      <thead>
      <tr>
          <th className='table-header'>Дата</th>
          <th className='table-header'>Менеджер</th>
          <th className='table-header'>Статус</th>
          <th className='table-header'>Группа</th>
          <th className='table-header'>Сообщение</th>
      </tr>
      </thead>
        <tbody>
        {filteredData.length > 0 ? (
          filteredData.map((mailing, index) => (
              <tr key={index} onClick={() => handleUserClick(mailing)}>
                  <td className='table-data'>
                      {new Date(mailing.date).toLocaleDateString('en-US')}
                  </td>
                  <td className='table-data'>{mailing.manager}</td>
                  <td className='table-data'>{mailing.status}</td>
                  <td className='table-data'>{mailing.group}</td>
                  <td className='table-data'>{mailing.message}</td>
              </tr>
          ))
        ) : (
            <tr>
            <td colSpan={5} className='table-data'>
              Нет данных для отображения
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

// Prop Types for MailingList
MailingList.propTypes = {
  filteredData: PropTypes.array.isRequired,
};

export default MailingList;
