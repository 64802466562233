import axiosInstance from './axiosInstance';

export const getDeals = async() => {
    try{
        console.log(process.env.REACT_APP_BASE_API_URL, 'process.env.REACT_APP_BASE_API_URL')
        const response = await axiosInstance.get("/deals");
        console.log(response, 'response')
        return response.data;
    }
    catch (error){
        console.error('Error fetching deals.', error);
        throw error
    }
}
