import MailingDashboard from './pages/MailingDashboard/MailingDashboard';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {PATHS} from './helpers/config';
import MailingHistory from './pages/MailingHistory/MailingHistory';
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    exact
                    path={PATHS.login.path}
                    element={<Login/>}
                /><Route
                exact
                path={PATHS.dashboard.path}
                element={<ProtectedRoute><MailingDashboard/></ProtectedRoute>}
            />
                <Route exact path={PATHS.history.path}
                       element={<ProtectedRoute><MailingHistory/></ProtectedRoute>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
