import { useState, useEffect, useRef } from 'react';
import SelectComponent from '../SelectComponent/SelectComponent';
import { getDeals } from "../../helpers/api/deals"; // Adjust the import path accordingly
import Input from "../form/Input";
import DataPicker from "../form/DataPicker";
import Button from "../form/Button";

const Filters = ({onFilteredIdsChange}) => {
    const [statuses, setStatuses] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cars, setCars] = useState([]);
    const [models, setModels] = useState([]); // To store models based on selected brand
    const [managers, setManagers] = useState([]); // New state for managers
    const dataRef = useRef(); // Use ref to store data

    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [filters, setFilters] = useState({
        searchName: '',
        startDate: '',
        endDate: '',
        selectedManager: '',
    });

    // Simulated state for loading and filtered groups
    const [loading, setLoading] = useState(false);
    const [filteredGroups, setFilteredGroups] = useState([]);

    // Fetch all data from the API
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await getDeals();
                console.log(data, 'API Response');
                dataRef.current = data; // Store data in ref

                // Extract unique statuses, regions, car brands, and managers
                const uniqueStatuses = [...new Set(data.deals.map(deal => deal.status))];
                const uniqueRegions = [...new Set(data.deals.map(deal => deal.provider_region))];
                const uniqueCars = [...new Set(data.deals.map(deal => deal.car_mark))];
                const uniqueManagers = [...new Set(data.deals.map(deal => deal.manager_name))]; // Extract unique managers

                setStatuses(uniqueStatuses.map(status => ({ id: status, name: status }))); // Format for SelectComponent
                setRegions(uniqueRegions.map(region => ({ id: region, name: region }))); // Format for SelectComponent
                setCars(uniqueCars.map(car => ({ id: car, name: car }))); // Format for SelectComponent
                setManagers(uniqueManagers.map(manager => ({ id: manager, name: manager }))); // Format for SelectComponent

                // Initialize filtered groups with the fetched data
                setFilteredGroups(data.deals);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Update models when a brand is selected
    const handleBrandChange = (brand) => {
        setSelectedBrand(brand);
        setSelectedModel(''); // Reset model when brand changes

        const filteredModels = [...new Set(dataRef.current.deals
            .filter(deal => deal.car_mark === brand)
            .map(deal => deal.car_model)
        )];

        setModels(filteredModels.map(model => ({ id: model, name: model })));
    };

    const handleInputChange = (field, value) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };
    const filterGroups = () => {
        if (!dataRef.current || !dataRef.current.deals) return;

        const { searchName, startDate, endDate, selectedManager } = filters;

        const filtered = dataRef.current.deals.filter(group => {
            const matchesName = group.group_name && group.group_name.toLowerCase().includes(searchName.toLowerCase());
            const matchesManager = selectedManager ? (group.manager_name && group.manager_name.toLowerCase() === selectedManager.toLowerCase()) : true;
            const matchesStatus = selectedStatus ? (group.status && group.status === selectedStatus) : true; // Filter by status
            const matchesRegion = selectedRegion ? (group.provider_region && group.provider_region === selectedRegion) : true; // Filter by region
            const matchesBrand = selectedBrand ? (group.car_mark && group.car_mark === selectedBrand) : true; // Filter by brand
            const matchesModel = selectedModel ? (group.car_model && group.car_model === selectedModel) : true; // Filter by model
            const matchesStartDate = startDate ? new Date(group.created_at) >= new Date(startDate) : true;
            const matchesEndDate = endDate ? new Date(group.created_at) <= new Date(endDate) : true;

            return matchesName && matchesManager && matchesStatus && matchesRegion && matchesBrand && matchesModel && matchesStartDate && matchesEndDate;
        });

        setFilteredGroups(filtered);

        onFilteredIdsChange(filtered.map(group => group.id));
    };

    // Clear filters
    const clearFilters = () => {
        setFilters({
            searchName: '',
            startDate: '',
            endDate: '',
            selectedManager: '',
        });
        setSelectedStatus('');
        setSelectedRegion('');
        setSelectedBrand('');
        setSelectedModel('');
        setSelectedGroups([]); // Clear selected groups
        setFilteredGroups(dataRef.current.deals); // Reset to all groups
    };


    // Call filterGroups whenever filters change
    useEffect(() => {
        filterGroups();

    }, [filters, selectedStatus, selectedRegion, selectedBrand, selectedModel]);

    return (
        <div className='filter-search'>
            <div className='filter-container'>
                <SelectComponent
                  title='Статус сделки'
                  options={statuses}
                  className='my-custom-class'
                  onChange={(value) => {
                      setSelectedStatus(value);
                      filterGroups();
                  }}
                  value={selectedStatus}
                />
                <SelectComponent
                  title='Регион поставщика'
                  options={regions}
                  className='my-custom-class'
                  onChange={(value) => {
                      setSelectedRegion(value);
                      filterGroups();
                  }}
                  value={selectedRegion}
                />
                <SelectComponent
                  title='Марка автомобиля:'
                  options={cars}
                  className='my-custom-class'
                  onChange={(value) => {
                      handleBrandChange(value);
                      filterGroups();
                  }}
                  value={selectedBrand}
                />
                {selectedBrand && (
                  <SelectComponent
                    title='Модель автомобиля:'
                    options={models}
                    className='my-custom-class'
                    onChange={(value) => {
                        setSelectedModel(value);
                        filterGroups();
                    }}
                    value={selectedModel}
                  />
                )}
            </div>
            <div className='search-container'>
                <p className='search-container__title'>Поиск групп</p>

                <div className='input-container'>
                    <Input
                        label='Название группы'
                        value={filters.searchName}
                        onChange={(value) => handleInputChange('searchName', value)}
                    />
                    <DataPicker
                        label='Дата создания группы (от)'
                        type='date'
                        value={filters.startDate}
                        onChange={(value) => handleInputChange('startDate', value)}
                        placeholder='дд /мм /гггг'
                    />
                    <DataPicker
                        label='Дата создания группы (до)'
                        type='date'
                        value={filters.endDate}
                        onChange={(value) => handleInputChange('endDate', value)}
                        placeholder='дд /мм /гггг'
                    />
                    <SelectComponent
                      title='Менеджер'
                      options={managers}
                      className='my-custom-class'
                      onChange={(value) => {
                          handleInputChange('selectedManager', value);
                          filterGroups(); // Call filterGroups after selecting a manager
                      }}
                      value={filters.selectedManager} // Changed from defaultValue to value
                    />
                    <div className='btn-reset'>
                        <Button onClick={clearFilters}>Сбросить фильтры</Button>
                    </div>
                </div>
                <div className='search-results'>
                    <h2 className='search-results__title'>Результаты поиска</h2>
                    {loading ? (
                        <p>Загрузка...</p>
                    ) : filteredGroups.length === 0 ? (
                        <p className='search-results__not-found'>Группы не найдены</p>
                    ) : (
                        <div className='search-results-container'>
                            <div className='search-results-header'>
                                <p>Статус</p>
                                <p>Группа</p>
                                <p className='provider-region'>Регион</p>
                                <p className='brand'>Марка</p>
                                <p >Модель </p>
                                <p>Дата создания</p>
                                <p>Менеджер</p>
                            </div>
                            <ul className='search-results-elements'>
                                {filteredGroups.map((group) => (
                                    <li key={group.id}>
                                        <p>{group.status}</p>
                                        <p>{group.group_name}</p>
                                        <p className='provider-region'>{group.provider_region}</p>
                                        <p className='brand'>{group.car_mark}</p>
                                        <p  className='model'>{group.car_model}</p>
                                        <p>{new Date(group.created_at).toLocaleDateString()}</p>
                                        <p>{group.manager_name}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default Filters;
