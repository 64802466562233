// mailingHistory.js
export const mailingHistory = [
  {
    id: 1,
    date: '2023-09-25',
    manager: 'Менеджер 1',
    status: 'Успешная',
    message: 'Сообщение о доставке 1',
    group: 'Группа 1',
  },
  {
    id: 2,
    date: '2023-09-26',
    manager: 'Менеджер 2',
    status: 'Неудачная',
    message: 'Сообщение о доставке 2',
    group: 'Группа 2'
  },
];
