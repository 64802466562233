import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import axiosInstance from "../../helpers/api/axiosInstance";

const ProtectedRoute = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Check if there is a token and if it is valid
        const checkAuthStatus = async () => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                try {
                    // Trying to verify the token or get user data

                    await axiosInstance.get("auth/verify");
                    setIsAuthenticated(true);
                } catch (error) {
                    setIsAuthenticated(false);
                }
            }
            setIsLoading(false);
        };

        checkAuthStatus();
    }, []);
    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (!isAuthenticated) {
        return <Navigate to='/login'/>
    }
    return children
};

export default ProtectedRoute;
