import PropTypes from 'prop-types';

const Button = ({
                  children,
                  onClick = null, // Устанавливаем значение по умолчанию
                  type = 'button', // Устанавливаем значение по умолчанию
                  size = 'medium', // Устанавливаем значение по умолчанию
                  variant = 'primary', // Устанавливаем значение по умолчанию
                  disabled = false, // Устанавливаем значение по умолчанию
                }) => {
  return (
      <button
          className={`btn btn-${variant} btn-${size}`}
          onClick={onClick}
          type={type}
          disabled={disabled}
      >
        {children}
      </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired, // Text or icon inside the button
  onClick: PropTypes.func, // Click handler
  type: PropTypes.oneOf(['button', 'submit', 'reset']), // Button types
  size: PropTypes.oneOf(['small', 'medium', 'large']), // Button size
  variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']), // Button style
  disabled: PropTypes.bool, // Disable the button
};

export default Button;
