import axios from 'axios';
import { PATHS } from '../config';

const BASE_API_URL = window._env_.REACT_APP_BASE_API_URL;
const apiUrl = BASE_API_URL;

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 error for token refresh
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const { data } = await axios.post(`${apiUrl}/auth/refresh`, {
          refresh_token: refreshToken,
        });

        // Save new access token
        localStorage.setItem('accessToken', data.access_token);
        originalRequest.headers['Authorization'] =
          `Bearer ${data.access_token}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        // Handle token refresh failure
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = PATHS.login.path;
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
