import axiosInstance from "./axiosInstance";

export const uploadFiles = async (formData) => {
  console.log(formData, 'formData')
  try {
    console.log(process.env.REACT_APP_BASE_API_URL, 'process.env.REACT_APP_BASE_API_URL')
    const response = await axiosInstance.post("/notify", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log(response, 'response upload');
    return response.data;
  } catch (error) {
    console.error('Error uploading file.', error);
    throw error;
  }
};

// import axiosInstance from "./axiosInstance";
//
// export const uploadFile = async (file, groupsIds, msg) => {
//   try {
//     const formData = new FormData();
//
//     // Append the file, groups_ids, and msg to the FormData object
//     formData.append('file', file);
//     formData.append('groups_ids', JSON.stringify(groupsIds)); // Assuming groupsIds is an array
//     formData.append('msg', JSON.stringify(msg)); // Assuming msg is an object
//
//     const response = await axiosInstance.post("/notify", formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//
//     console.log(response, 'response upload');
//     return response.data;
//   } catch (error) {
//     console.error('Error uploading file.', error);
//     throw error;
//   }
// };
