import Wrapper from '../../components/Wrapper/Wrapper';
import { useState, useRef, useEffect } from 'react';
import Filters from '../../components/Filters/Filters';
import { uploadFiles } from '../../helpers/api/upload';
import {MdCloudUpload} from "react-icons/md";

const MailingDashboard = () => {
    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [status, setStatus] = useState(null);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [filteredIds, setFilteredIds] = useState([]);
    const previewRef = useRef(null);

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setAttachments(files);
    };

    const handleFilteredIdsChange = (ids) => {
        setFilteredIds(ids);
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //
    //     try {
    //         // Check if there are files to upload
    //         if (attachments.length === 0) {
    //             throw new Error("Please upload at least one file.");
    //         }
    //
    //         // Loop through the attachments and upload each one
    //         for (const file of attachments) {
    //             await uploadFile(file, filteredIds, { message });
    //         }
    //
    //         setStatus('Рассылка успешно создана!');
    //
    //         // Clear fields after submission
    //         setMessage('');
    //         setAttachments([]);
    //         setFilteredIds([]); // Clear filtered IDs
    //         setIsPreviewVisible(false);
    //     } catch (error) {
    //         console.error('Error submitting form:', error);
    //         setStatus('Ошибка отправки, пожалуйста, попробуйте еще раз.');
    //     }
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Check if there are files to upload
            if (attachments.length === 0) {
                throw new Error("Please upload at least one file.");
            }

            // Prepare the request data
            const formData = new FormData();

            // Append the file(s)
            attachments.forEach((file) => {
                console.log(file, 'filefffff');
                formData.append('file', file);
            });
            // Prepare groups_ids and msg
            formData.append('groups_ids', JSON.stringify({ group_ids: filteredIds }));
            console.log(filteredIds, 'filteredIds');
            formData.append('msg', JSON.stringify({ text: message }));
            console.log(message, 'message');

            // Send the request
            const response = await uploadFiles(formData);

            setStatus('Рассылка успешно создана!');

            // Clear fields after submission
            setMessage('');
            setAttachments([]);
            setFilteredIds([]);
            setIsPreviewVisible(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            setStatus('Ошибка отправки, пожалуйста, попробуйте еще раз.');
        }
    };


    const togglePreview = (e) => {
        e.preventDefault();
        setIsPreviewVisible((prev) => !prev);
    };

    useEffect(() => {
        if (isPreviewVisible && previewRef.current) {
            previewRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isPreviewVisible]);

    return (
      <Wrapper>
          <div id='MailingDashboard'>
              <form onSubmit={handleSubmit}>
                  <h1 className='MailingDashboard__title'>Панель Рассылок</h1>
                  <Filters onFilteredIdsChange={handleFilteredIdsChange} />
                  <div className='message-upload-container'>
                      <div className='message-container'>
                          <label>Сообщение:</label>
                          <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                          ></textarea>
                      </div>
                      <div className='upload-submit-container'>
                          <div className='upload-container'>
                              <div className='file-upload-container'>
                                  <label htmlFor='file-upload' className='custom-file-upload'>
                                      {/*Загрузить вложения (PDF, изображения)*/}
                                      <MdCloudUpload />
                                      Загрузить
                                  </label>
                                  <input
                                    id='file-upload'
                                    type='file'
                                    accept='.pdf, image/*'
                                    onChange={handleFileUpload}
                                    multiple
                                  />
                              </div>
                          </div>
                          <div className='buttons-container'>
                              <button className='btn-preview' onClick={togglePreview}>
                                  {isPreviewVisible ? 'Скрыть Предпросмотр' : 'Предпросмотр'}
                              </button>
                              <button className='btn-submit' type='submit'>
                                  Отправить
                              </button>
                          </div>
                      </div>
                  </div>
              </form>

              {status && <p style={{ marginBottom: '40px', color: 'green' }}>{status}</p>}

              {isPreviewVisible && (
                <div className={`toggle-block preview-section ${isPreviewVisible ? 'open' : ''}`}
                     ref={previewRef}>
                    <h2>Предпросмотр</h2>
                    <div className='preview-message'>
                        <h4>Сообщение:</h4>
                        <p>{message}</p>
                    </div>
                    {attachments.length > 0 && (
                      <div className='preview-attachments'>
                          <h4>Вложения:</h4>
                          <ul>
                              {attachments.map((file, index) => (
                                <li key={index}>{file.name}</li>
                              ))}
                          </ul>
                      </div>
                    )}
                </div>
              )}
          </div>
      </Wrapper>
    );
};

export default MailingDashboard;
