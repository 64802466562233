import { useState } from 'react';
import PropTypes from 'prop-types';
import DataPicker from '../form/DataPicker';
import SelectComponent from '../SelectComponent/SelectComponent';


// Define static status options outside the component
const statuses = [
  { id: 1, name: 'Успешная' },
  { id: 2, name: 'Неудачная' },
];
const managers = [
  { id: 1, name: 'Менеджер 1' },
  { id: 2, name: 'Менеджер 2' },
];

const HistoryFilter = ({ onFilterChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedManager, setSelectedManager] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null); // Set to null for clarity

  // Function to handle filter changes and pass the filters to the parent
  const handleFilterChange = () => {
    const filters = {
      date: selectedDate,
      manager: selectedManager,
      status: selectedStatus,
    };
    onFilterChange(filters);
    console.log(filters, 'filters');
  };

  return (
    <div className='filter-form'>
      <DataPicker
        label='Дата отправки'
        value={selectedDate}
        onChange={setSelectedDate}
        placeholder='дд/мм/гггг'
      />
      <SelectComponent
        title='Статус рассылки'
        options={statuses}
        className='my-custom-class'
        onChange={setSelectedStatus} // Directly set state
        defaultValue={selectedStatus}
      />
      <SelectComponent
        title='Менеджер'
        options={managers}
        className='my-custom-class'
        onChange={setSelectedManager} // Directly set state
        defaultValue={selectedManager}
      />
      <button onClick={handleFilterChange}>Применить фильтры</button>
    </div>
  );
};

// Define PropTypes for the component
HistoryFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default HistoryFilter;
