import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import '../../assets/styles/style.scss';

const SelectComponent = ({
                           title,
                           options,
                           className = '', // Устанавливаем значение по умолчанию
                           onChange = null, // Устанавливаем значение по умолчанию
                           value = '', // Устанавливаем значение по умолчанию
                         }) => {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
      <FormControl fullWidth className={`custom-select ${className}`}>
        <InputLabel id={`${title}-label`}>{title}</InputLabel>
        <Select
            labelId={`${title}-label`}
            id={`${title}-select`}
            value={value}
            label={title}
            onChange={handleChange}
        >
          <MenuItem value=''></MenuItem>
          {options.map((option) => (
              <MenuItem key={option.id} value={option.name}>
                {option.name}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

SelectComponent.propTypes = {
  title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }),
    ).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default SelectComponent;
