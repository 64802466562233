import NavBar from '../NavBar/NavBar';
import PropTypes from 'prop-types';
import {PATHS} from "../../helpers/config";
import {useLocation} from "react-router-dom";

const Wrapper = (props) => {
    const paths = Object.values(PATHS).map((item) => item.path);
    const location = useLocation()
    const pathname = location.pathname;
    const isNavBarVisible = paths.includes(pathname) && pathname !== PATHS.login.path
  return (
    <div id='Wrapper'>
      <div className='container'>
          {isNavBarVisible ? <NavBar /> : null}
          {/*<NavBar />*/}
        {props.children}
      </div>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Wrapper;
