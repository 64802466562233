import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, FormControl } from '@mui/material';

const Input = ({
                 label,
                 type = 'text', // Устанавливаем значение по умолчанию
                 value,
                 onChange,
                 placeholder = '', // Устанавливаем значение по умолчанию
                 className = '', // Устанавливаем значение по умолчанию
               }) => {
  return (
      <FormControl fullWidth className={`input ${className}`}>
        <TextField
            label={label}
            type={type}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            variant='outlined'
            fullWidth
        />
      </FormControl>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default Input;
