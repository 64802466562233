import axiosInstance from "./axiosInstance";

export const login = async (data) => {
    try {
        console.log(process.env.REACT_APP_BASE_API_URL, 'process.env.REACT_APP_BASE_API_URL')
        const response = await axiosInstance.post('/auth/login', data,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        console.log("Error details:", error.response?.data || error.message);
        throw error;
    }
};
