import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField, FormControl } from '@mui/material';

const DataPicker = ({
                        label,
                        value,
                        onChange,
                        placeholder = '', // Устанавливаем значение по умолчанию
                        className = '', // Устанавливаем значение по умолчанию
                    }) => {
    return (
        <FormControl fullWidth className={`input ${className}`}>
            <DatePicker
                selected={value ? new Date(value) : null}
                onChange={(date) => onChange(date)}
                placeholderText={placeholder}
                customInput={
                    <TextField
                        label={label}
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                    />
                }
                dateFormat='dd/MM/yyyy' // Устанавливаем формат даты
            />
        </FormControl>
    );
};

DataPicker.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

export default DataPicker;
