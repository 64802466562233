import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {login} from "../../helpers/api/auth";

const Login = () => {
    const { register, handleSubmit,
        formState: { errors } } = useForm();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            // Assuming the login function handles API call and returns tokens
            const result = await login({
                username: data.username,
                password: data.password,
            });
            // Saving tokens to localStorage
            localStorage.setItem("accessToken", result.access_token);
            localStorage.setItem("refreshToken", result.refresh_token);
            localStorage.setItem("username", data.username);

            // Redirect to dashboard or homepage
            navigate("/");
        } catch (error) {
            console.log("Ошибка при входе:", error.response?.data || error.message);
        }
    };

    return (
        <Wrapper>
            <div id="Login">
                <h1 className="Login__title">Рассылка сообщений</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-container">
                        <TextField
                            label="Имя пользователя"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("username", {
                                required: "Имя пользователя обязательно",
                            })}
                            error={!!errors.username}
                            helperText={errors.username ? errors.username.message : ""}
                        />
                        <TextField
                            label="Пароль"
                            type="password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            {...register("password", {
                                required: "Пароль обязателен",
                            })}
                            error={!!errors.password}
                            helperText={errors.password ? errors.password.message : ""}
                        />
                        <button type="submit">Войти</button>
                    </div>
                </form>
            </div>
        </Wrapper>
    );
};

export default Login;
