import { useState, useEffect } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import HistoryFilter from '../../components/HistoryFilter/HistoryFilter';
import { mailingHistory } from '../../helpers/mailingHistory';
import MailingList from '../../components/MailingList/MailingList';

const MailingHistory = () => {
  const [mailings, setMailings] = useState([]);
  const [filteredMailings, setFilteredMailings] = useState(mailings); // Initialize with mailings
  const [loading, setLoading] = useState(false); // Changed to false as we use static data
  const [error, setError] = useState(null);

  // Filter handling function
  const handleFilterChange = (filters) => {
    const filtered = mailings.filter((mailing) => {
      const matchesDate = filters.date
        ? new Date(mailing.date).toDateString() === filters.date.toDateString()
        : true;
      const matchesManager = filters.manager
        ? mailing.manager === filters.manager
        : true;
      const matchesStatus = filters.status
        ? mailing.status === filters.status
        : true;
      return matchesDate && matchesManager && matchesStatus;
    });
    setFilteredMailings(filtered);
  };

  useEffect(() => {
    setMailings(mailingHistory);
    setFilteredMailings(mailingHistory);
    setLoading(false); // Mark loading as complete
  }, []);

  return (
    <Wrapper>
      <div id='MailingHistory'>
        <h1 className='MailingHistory__title'>История Рассылок</h1>
        <HistoryFilter onFilterChange={handleFilterChange} />
        {error && <p>{error}</p>}
      </div>
      <MailingList filteredData={filteredMailings} />
    </Wrapper>
  );
};

export default MailingHistory;
